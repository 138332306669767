import React from "react";

// Affiches
import Vrijdag from "resources/affiche_vrijdag.jpg";
import Zaterdag from "resources/affiche_zaterdag.png";
import Zondag from "resources/affiche_zondag.png";
import Maandag from "resources/affiche_maandag.png";

// Styling
import styled from "styled-components";

const Title = styled.h1``;

const Description = styled.h2`
  opacity: ${({ hide }) => (hide ? "0" : "1")};

  @media (max-width: 768px) {
    display: ${({ hide }) => (hide ? "none" : "visible")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  margin-left: 100px;
  margin-right: 100px;
  overflow: auto;
  @media (max-width: 768px) {
    flex-flow: column;

    margin-left: 5px;
    margin-right: 5px;
  }
`;

const VerticalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 75%;

  @media (max-width: 768px) {
    height: 100%;
    width: auto;
  }
`;

const Affiche = styled.img`
  width: auto;
  height: 100%;
`;

const ProgramPage = () => {
  return (
    <Wrapper>
      <VerticalWrapper>
        <Title>Vrijdag 4 oktober</Title>
        <Description hide={true}>{"."}</Description>
        <Affiche src={Vrijdag} />
      </VerticalWrapper>
      <VerticalWrapper>
        <Title>Zaterdag 5 oktober</Title>
        <Description>
          Bestel je tickets{" "}
          <a
            href="https://www.your-tickets.be/events/AmbianceoptKaaiken2024"
            target="_blank"
            rel="noopener noreferrer"
          >
            hier
          </a>
        </Description>
        <Affiche
          src={Zaterdag}
          onClick={() => {
            window.open(
              "https://www.your-tickets.be/events/AmbianceoptKaaiken2024",
              "_blacnk"
            );
          }}
        />
      </VerticalWrapper>
      <VerticalWrapper>
        <Title>Zondag 6 oktober</Title>
        <Description hide={true}>{"."}</Description>
        <Affiche src={Zondag} />
      </VerticalWrapper>
      <VerticalWrapper>
        <Title>Maandag 7 oktober</Title>
        <Description hide={true}>{"."}</Description>
        <Affiche src={Maandag} />
      </VerticalWrapper>
    </Wrapper>
  );
};

export default ProgramPage;
