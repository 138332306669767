export const isPersonFromNeighbourhood = (user) => {
  switch (user.Straat) {
    case "Blekerij":
    case "Broeders van Liefdestraat":
    case "Burggravenstraat":
    case "Dullaert":
    case "Euerardstraat":
    case "Ganzepoel":
    case "Jan Breydelstraat":
    case "Kaaistraat":
    case "Krugercenter":
    case "Pieter De Coninckstraat":
    case "Raashekke":
    case "Schuttershof":
    case "Visstraat":
    case "Zuidmoerstraat":
      if (user.Gemeente === "Eeklo") {
        return true;
      }
      return false;
    default:
      return false;
  }
};

export const enkelBeschikbarePlaatsen = (plaatsen) => {
  const nietBeschikbareInZuidmoer = [
    "899Y",
    "899X",
    "899W",
    "899V",
    "899U",
    "899T",
    "899S",
    "899R",
    "899Q",
    "899P",
    "899O",
    "899N",
  ];

  const allePlaatsen = plaatsen.filter((x) => {
    return (
      x.Straat !== "Schuttershof" &&
      x.Straat !== "Jan Breydelstraat" &&
      x.Straat !== "Pieter De Coninckstraat" &&
      x.Straat !== "Kaaistraat" &&
      !nietBeschikbareInZuidmoer.includes(x.Standplaats)
    );
  });

  return allePlaatsen;
};
